    <template>
<div class="" @click="viewAppointmentDetails" style="width: 100%;">
    <div class="mainContainers" >
        <img :src="appointmentInfo.packageBanner" alt="" style="border-top-left-radius: inherit; border-top-right-radius: inherit; height: 150px; object-fit: cover; width: 100%;">
        <div style="display: flex; flex-direction: column; gap: 16px; width: 100%; padding: 16px; padding-top: 0;">
            <div class="mainContainerPackageName">
                <div style="" class="commonFlexBox">
                    <span style="color: #828282; font-weight: 400; font-size: 14px;">{{$t("Customer.MySubscription.Package")}}:</span>
                    <span style="font-weight: 500; font-size: 14px;">{{ isEngLanguage() ? appointmentInfo.packageNameEN : appointmentInfo.packageNameMM }}</span>
                </div>
                <div style="" class="commonFlexBox" >
                    <span style="color: #828282; font-weight: 400; font-size: 14px;">{{$t("Customer.PackageAppointments.Applicable_for")}}:</span>
                    <div style="flex-wrap: wrap; display: flex;">
                    <span style="font-weight: 500; font-size: 14px;" v-for="(applicable, index) in appointmentInfo.applicableFor" :key="index">{{ applicable }}{{ index != appointmentInfo.applicableFor.length -1 ? ', ': '' }}</span>
                </div>
                    <span style="font-weight: 500; font-size: 14px;"></span>
                </div>
            </div>
            <div class="mainContainerPackageName">
                <div style="" class="rowFlexBox">
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/clock.svg" alt="">
                    <span style="font-weight: 500; font-size: 13px;">{{ appointmentInfo.timeInfo }}</span>
                </div>
                <div style="" class="rowFlexBox" >
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/clock.svg" alt="">
                    <span style="font-weight: 500; font-size: 13px;">{{ formatDate(appointmentInfo.bookingDateNumber) }}</span>
                </div>
            </div>
            <div class="mainContainerPackageName">
                <div class="hospitalNameContainer">
                    <div class="hospitalLogo">
                        <img :src="appointmentInfo.hospitalLogo" style="max-width: 100%; max-height: 100%; object-fit: contain; border-radius: 8px;"/>
                    </div>
                    <div class="hospitalName">
                        <div style="font-size: 14px; font-weight: 600; color: #333333;">{{ appointmentInfo.hospitalName }}</div>
                        <div style="font-size: 12px; font-weight: 500; color: #4f4f4f;">{{ appointmentInfo.hospitalAddress }}</div>
                    </div>
                </div>
            </div>
            <div v-if="completedFlag" style="display: flex; flex-direction: column; align-items: flex-start; border-top: 1px solid #f2f2f2; padding-top: 16px;">
                <div style="font-size: 14px; font-weight: 400;">Rate overall experience</div>
                <div style="display: flex; gap: 10px; padding-top: 5px;">
                    <div v-for="i in 5" :key="i" >
                    <img src="https://d3vsnl8idgwrlw.cloudfront.net/whiteHeart.svg" style="width: 25px; height: 25px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>    
<script>
    
    
    
    export default {
        name: 'singlePackageAppointmentComponent',
        props: ['completedFlag', 'appointmentInfo'],
        data: function() {
            return {
                // completedFlag: true,
            };
        },
        computed: {
            textPreview() {
                return this.fullDescription.slice(0, 100) + '...';
            }
        },
        async mounted() {
        },
        methods: {
            isEngLanguage() {
                try {
                    return this.$i18n.locale=='en' ;
                } catch (error) {
                    return true;
                }
            },
            viewAppointmentDetails () {
                this.$router.push({
                    name: 'particularPackageAppointment',
                    params: {
                        'bookingID': this.appointmentInfo.bookingID
                    }
                })
            },
            calculateStyle() {
                if(this.completedFlag) {
                    return 'color: #bdbdbd;'
                } else {
                    return 'color: #1467BF;'
                }
            },
            goToOPDPage() {
                this.$router.push({
                    name: 'OPDHome'
                })
            },
            formatDate(inputDate) {
                inputDate = inputDate.toString();

                let year = inputDate.slice(0, 4);
                let month = inputDate.slice(4, 6);
                let day = inputDate.slice(6, 8);

                return `${year}-${month}-${day}`;
            },
            toggleHospitalDetails() {
                this.viewDetailsModel = !this.viewDetailsModel;
            },
            closeBottomSheet() {
                this.viewDetailsModel = false;
            },
            descriptionPreview() {
    
            }
        },
    };
    
    </script>
    <style  scoped>
    .mainContainer {
        padding: 16px;
        border: 1px solid #e0e0e0;
        border-radius: 12px;
        background: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        cursor: pointer;
    }
    
    .doctorName {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }
    .doctorProfile {
        border-radius: 50%;
        max-height: 56px;
        max-width: 56px;
        height: 56px;
        width: 56px;
        border: 1px solid #e0e0e0;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .doctorNameAndLogo {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-grow: 1;
    }
    .specialtyBox {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E4F5FF;
        padding: 3px 8px 3px 8px;
    }
    .tokenNumber {
        height: 32px;
        width: 32px;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        /* color: #bdbdbd; */
    }
    .hospitalName {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    .hospitalNameAndLogo {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        font-size: 14px !important;
    }
    .mainContainerPackageName {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background: red;  */
    align-items: center;
}
.commonFlexBox {
    display: flex; flex-direction: column; gap: 4px; align-items: flex-start; 
}
.mainContainers {
    /* padding: 16px; */
    background: white;
    border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
    z-index: -2122;
}
.rowFlexBox {
    display: flex;
    align-items: center;
    gap: 8px;
}
.rowFlexBox img {
    width: 28px;
    height: 28px;
}
.hospitalNameContainer {
    display: flex;
    gap: 8px;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    min-width: 48px;
    max-width: 48px;
    max-height: 48px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>
    